@tailwind base;
@tailwind components;
@tailwind utilities;

@import "semantic-ui-css/semantic.min.css";
@import "sweetalert2/dist/sweetalert2.css";

body {
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  font-weight: 400 !important;
  padding: 0;
  margin: 0;
  background-color: #e5e5e5;
}

button,
input,
optgroup,
select,
textarea{
    font-family: "Rubik", sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: "Rubik", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;

  -moz-osx-font-smoothing: grayscale !important;
}

@font-face {
  font-family: "Rubik";
  src: url("./static/fonts/Rubik-VariableFont_wght.ttf");
  font-weight: 100 900;
}

a {
  text-decoration: none;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.text-center {
  text-align: center;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.mr-2 {
  margin-right: 0.5rem;
}

.main-content {
  max-width: 1440px;
  margin: 40px auto 25px auto;
  padding-right: 20px;
  padding-left: 20px;
  box-sizing: border-box;
  min-height: 426px;
}

.searchBar {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  padding: 42px 40px;
  z-index: 9;
  transform: translateY(-100%);
  opacity: 0;
  transition: all 0.2s ease;
}

.searchBar.on {
  transform: translateY(0);
  opacity: 1;
}

.searchBar .search {
  padding: 2px 4px 2px 2px;
  width: 600px;
  /* margin: auto; */
  border: 1px solid #dedddd;
  box-sizing: border-box;
  border-radius: 3px;
}

.searchBar input {
  font-size: 20px;
  line-height: 32px;
  color: #222222;
  padding: 10px 10px 10px 25px;
  border: unset;
  outline: none;
  width: 100%;
  background-color: unset;
}

.searchBar input::placeholder {
  color: #b0b0b0;
}

.searchBar button {
  font-size: 18px;
  height: 48px;
  width: 48px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.closeSearch {
  width: 48px;
  height: 48px;
  font-size: 18px;
  color: #222222;
  background-color: rgb(34 34 34 / 5%);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
}

.closeSearch:hover {
  color: #ffffff;
  background-color: #8ac04a;
}

.headerRow,
.portalFooter .footerRow {
  max-width: 1440px;
  margin: auto;
}

.bottomHeader .headerRow {
  padding: 10px 10px 10px 0px;
}

.headerRow {
  padding: 10px;
  box-sizing: border-box;
}

.header .topHeader {
  background-color: #222222;
  position: relative;
}

/* .header .topHeader .headerRow { */
/* padding: 8px 0;	 */
/* } */

.topHeader .topHeaderLeft .topHeaderLogo {
  margin-right: 20px;
}

.topHeader .topHeaderLeft .topHeaderLogo a {
  display: flex;
}

.topHeader .topHeaderLeft .topHeaderLogo img {
  width: 160px;
}

.topHeader .topHeaderLeft .topHeaderMenu li:not(:last-child) {
  margin-right: 10px;
}

.topHeader .topHeaderLeft .topHeaderMenu li a {
  font-size: 14px;
  line-height: 28px;
  color: rgb(255 255 255 / 60%);
}

.topHeader .topHeaderLeft .topHeaderMenu li a:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight {
  display: flex;
  align-items: center;
}

.topHeader .topHeaderRight .LogoutBtn {
  margin-left: 10px;
}

.topHeader .topHeaderRight .LogoutBtn button {
  font-size: 16px;
  color: rgb(255 255 255 / 60%);
  background-color: rgb(255 255 255 / 20%);
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 12px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topHeader .topHeaderRight .LogoutBtn button:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight .services .selectedService {
  color: #ffffff;
}

.topHeader .topHeaderRight .services .changeService {
  color: rgb(255 255 255 / 60%);
  cursor: pointer;
}

.topHeader .topHeaderRight .services .changeService:hover {
  color: #ffffff;
}

.topHeader .topHeaderRight .services {
  font-size: 13px;
  background: rgb(255 255 255 / 20%);
  border-radius: 3px;
  padding: 0 15px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.topHeader .topHeaderRight .services .selectedService {
  margin-right: 8px;
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.topHeader .topHeaderRight .services > span:last-child svg {
  margin-left: 5px;
}

/*
.topHeader .topHeaderMenu > ul > li:not(:last-child):not(:nth-last-child(2)) {
	padding: 17px 15px;
	position: relative;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)):hover {
	background-color: #222222;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) * {
	font-size: 16px;
	color: rgb(255 255 255 / 60%);
}

.topHeader .topHeaderMenu ul li .messages {
	font-size: 13px !important;
    color: #ffffff !important;
    background-color: #FF5F5F;
    border-radius: 2px;
    padding: 2px 6px;
}

.topHeader .topHeaderMenu ul li:not(:last-child):not(:nth-last-child(2)) svg {
	font-size: 13px;
}

.topHeader .topHeaderMenu ul li:hover > .sub-menu {
    clip: auto;
    opacity: 1;
    transform: translateY(0) scaleY(1);
}

.topHeader .topHeaderMenu ul li .sub-menu {
    clip: rect(1px, 1px, 1px, 1px);
    opacity: 0.1;
    transition: transform .5s cubic-bezier(0.2, 0, 0.2, 1), opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
    transform: translateY(-10px) scaleY(0.5);
    transform-origin: top;
    list-style: none;
    margin: 0;
    padding: 10px 15px;
	width: 130px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #222222;
    z-index: 99;
}

.topHeader .topHeaderMenu ul li .sub-menu li a {
    padding: 10px;
    display: block;
}

.topHeader .userIcon {
	background-color: #8BC334;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	margin-right: 0.625rem;
}

.topHeader .userIcon svg {
	color: #ffffff;
}

.topHeader .topHeaderMenu > ul > li:nth-last-child(2) {
	padding: 5px 25px 5px 5px;
    border-radius: 22px;
}
*/

/* .topHeader .topHeaderMenu > ul > li:last-child, */
/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2), */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *, */
/* .topHeader .services * { */
/* font-size: 13px; */
/* } */

/* .topHeader .topHeaderMenu ul li:nth-last-child(2) .userInfo *:first-child, */
/* .topHeader .topHeader .topHeaderMenu ul li:last-child svg, */
/* .services *:first-child { */
/* color: #ffffff; */
/* line-height: 1.5;  */
/* } */

/* .topHeader .topHeaderMenu > ul > li:nth-last-child(2) .userInfo *:last-child, */
/* .topHeader .topHeaderMenu > ul > li:last-child, */
/* .topHeader .services *:last-child { */
/* color: rgb(255 255 255 / 60%); */
/* cursor: pointer; */
/* line-height: 1; */
/* } */
/*
.topHeader .topHeaderMenu > ul > li:last-child {
    width: 42px;
    height: 42px;
	border-radius: 50%;
	margin-left: 5px;
}

.topHeader .topHeaderMenu ul li:last-child svg {
	color: #ffffff;
	font-size: 17px;
}
*/
.header .bottomHeader {
  background-color: #ffffff;
  position: relative;
}

/* .header .bottomHeader .headerRow { */
/* padding: 10px 0; */
/* } */

/* .bottomHeader .logo { */
/* padding-left: 5px; */
/* } */

.mobileMenu {
  display: flex;
  justify-content: flex-end;
}

.mobileMenu .menuBars {
  width: 60px;
  height: 60px;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
}

.mobileMenu .menuBars .bars {
  position: relative;
  width: 30px;
  height: 22px;
  border-top: 3px solid;
  border-bottom: 3px solid;
  transition: all 0.2s ease;
  margin-right: 0 !important;
}

.mobileMenu .menuBars .bars:after,
.mobileMenu .menuBars .bars:before {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -1px;
  left: 0;
  width: 100%;
  height: 3px;
  background: #222222;
  transition: all 0.2s ease;
}

.mobileMenu .menuBars.on .bars:before {
  transform: rotate(45deg);
}

.mobileMenu .menuBars.on .bars:after {
  transform: rotate(-45deg);
}

.mobileMenu .menuBars.on .bars {
  border-color: transparent;
}

.bottomHeader .logo img {
  width: 100%;
  max-width: 245px;
}

.bottomHeader .logo a {
  display: flex;
}

.bottomHeader .headerMenu {
  display: flex;
  align-items: center;
}

.bottomHeader .headerMenu .searchBtn {
  margin-left: 10px;
  display: none !important;
}

.bottomHeader .headerMenu .searchBtn button {
  width: 47px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background: rgb(34 34 34 / 6%);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}

.bottomHeader .headerMenu .searchBtn button:hover {
  color: #ffffff;
  background-color: #8ac04a;
}

.bottomHeader .headerMenu .mainHeaderMenu {
  display: none;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem {
  position: relative;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem:not(:last-child) {
  margin-right: 5px;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
  font-size: 17px;
  text-transform: uppercase;
  color: #222222;
  padding: 10px 15px;
  display: block;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .subMenu {
  display: block;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem .subMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  display: none;
  z-index: 9;
  padding-top: 25px;
  margin-top: 35px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu
  .subMenuItem {
  background-color: #8ac04a;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown
  .subMenu
  .subMenuItem
  a {
  color: #ffffff !important;
  line-height: 32px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem.active,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem
  .subMenu
  .subMenuItem:hover {
  background: #92c457;
}

.bottomHeader .headerMenu .mainHeaderMenu .menuItem.active .menuItemText,
.bottomHeader .headerMenu .mainHeaderMenu .menuItem .menuItemText:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown.bg-portalGreen
  .menuItemText,
.bottomHeader
  .headerMenu
  .mainHeaderMenu
  .menuItem.hasDropdown:hover
  > .menuItemText {
  color: #8ac04a !important;
  background-color: #e8f2db;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu {
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  background-color: #ffffff;
  z-index: 9;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0.1;
  transition: transform 0.5s cubic-bezier(0.2, 0, 0.2, 1),
    opacity 0.2s cubic-bezier(0.2, 0, 0.2, 1);
  transform: translateY(-10px) scaleY(0.5);
  transform-origin: top;
  box-shadow: 0 5px 10px 0 rgb(0 0 0 / 20%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu.slideDown {
  clip: auto;
  opacity: 1;
  transform: translateY(0) scaleY(1);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li {
  border-top: 1px solid #ebebeb;
  /* padding: 15px 0; */
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu .subMenu {
  display: none;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem:not(:last-child) {
  border-bottom: 1px solid #c35591;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem
  .subMenuItemText {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader
  .mobileMenu
  .mainHeaderMobileMenu
  .subMenu
  .subMenuItem
  .subMenuItemText:hover {
  background: #92c457;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu li > a {
  font-size: 20px;
  text-transform: uppercase;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > a {
  display: inline-block;
  padding: 10px 15px;
  margin: 15px 0;
  color: #222222;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > ul li:not(:last-child) {
  border-bottom: 1px solid rgb(138 192 74 / 60%);
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li > ul li a {
  color: #ffffff;
  background: #8ac04a;
  width: 100%;
  display: block;
  padding: 25px 0;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.active a,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li a:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border-radius: 3px;
}

.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.hasDropdown.active a,
.bottomHeader .mobileMenu .mainHeaderMobileMenu > li.hasDropdown a:hover {
  color: #8ac04a;
  background-color: #f3f9ed;
}

.portalFooter h3 {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
  margin-bottom: 30px;
}

.portalFooter .footerTop {
  background-color: #222222;
}

.portalFooter .footerTop .footerRow {
  padding: 35px 50px 45px 50px;
  box-sizing: border-box;
}

.products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.products .product {
  background: rgb(255 255 255 / 10%);
  border-radius: 3px;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.products .product svg {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255 255 255 / 40%);
  font-size: 14px;
}

.products .product span {
  font-size: 16px;
  line-height: 28px;
  color: rgb(255 255 255 / 40%);
}

.products .product a {
  /* font-size: 16px; */
  color: #ffffff;
  padding: 4px 8px;
  border-radius: 2px;
  margin-top: 5px;
}

.products .product.commingSoon a {
  background-color: #ff8888;
  cursor: not-allowed;
}

.products .product.visit a {
  background-color: #8ac04a;
}

.products .product.subscribed a {
  background-color: #8ac04a;
}

.products .product:not(.commingSoon):hover,
.products .product.subscribed.active {
  background-color: #8ac04a;
}

.products .product:not(.commingSoon):hover svg,
.products .product:not(.commingSoon):hover span,
.products .product.subscribed.active svg,
.products .product.subscribed.active span {
  color: #ffffff;
}

.products .product:not(.commingSoon):hover a,
.products .product.subscribed.active a {
  color: #8ac04a;
  background-color: #ffffff;
}

.portalFooter .footerBottom {
  background-color: #8ac04a;
}

.footerBottom .footerMenu ul li:not(:last-child) {
  margin-right: 15px;
  border-right: 1px solid rgb(255 255 255 / 60%);
  padding-right: 15px;
}

.footerBottom .footerCopyright span,
.footerBottom .footerMenu li .footerMenuText {
  font-size: 16px;
  line-height: 19px;
  color: rgb(255 255 255 / 60%);
}

.footerBottom .footerMenu li .footerMenuText:hover {
  color: #ffffff;
}

.contentHeader {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  /* margin-bottom: 16px; */
  /* width: 100%; */
}

.backBtn a {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: rgb(34 34 34 / 20%);
  background: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  padding: 12px 10px;
}

.backBtn a:hover {
  color: #222222;
}

.backBtn a > * {
  margin-right: 8px;
}

.topHeaderContainer.links-page {
  margin-bottom: 10px;
}

.topHeaderContainer {
  margin-bottom: 30px;
  padding-left: 30px;
}

.topHeaderContainer .search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
  /* width: 330px; */
}

.topHeaderContainer .search input {
  font-size: 16px;
  line-height: 10px;
  color: #b0b0b0;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 190px;
}

.topHeaderContainer .search button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.topHeaderContainer .search button:hover {
  opacity: 0.8;
}

.tabHeader {
  background-color: #ffffff;
}

.tabHeader .menu {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.tabHeader .menu li:not(:last-child) {
  border-right: 4px solid #e5e5e5;
}

.tabHeader .menu li {
  border-bottom: 4px solid #e5e5e5;
}

.tabHeader .menu li:hover,
.tabHeader .menu li.active {
  font-weight: 400;
  color: #222222;
}

.tabHeader .menu li.active {
  border-bottom: 4px solid #ffffff;
}

.tabHeader ul li:nth-child(1):hover::before,
.tabHeader ul li:nth-child(1).active::before {
  background-color: #61a447;
}

.tabHeader ul li:nth-child(2):hover::before,
.tabHeader ul li:nth-child(2).active::before {
  background-color: #fbd539;
}

.tabHeader ul li:nth-child(3):hover::before,
.tabHeader ul li:nth-child(3).active::before {
  background-color: #e395a5;
}

.tabHeader ul li:nth-child(4):hover::before,
.tabHeader ul li:nth-child(4).active::before {
  background-color: #accf6b;
}

.tabHeader ul li:nth-child(5):hover::before,
.tabHeader ul li:nth-child(5).active::before {
  background-color: #f3a23e;
}

.tabHeader ul li:nth-child(6):hover::before,
.tabHeader ul li:nth-child(6).active::before {
  background-color: #db308f;
}

.tabHeader ul li:nth-child(7):hover::before,
.tabHeader ul li:nth-child(7).active::before {
  background-color: #3a96cf;
}

.tabHeader li:hover::before,
.tabHeader li.active::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-370%, -50%);
}

.tabHeader .menu .tabMenuItem {
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  padding: 10px 0;
  text-align: center;
  display: block;
  color: rgb(34 34 34 / 40%);
  position: relative;
  cursor: pointer;
}

.documentsList {
  background-color: #ffffff;
  padding: 30px;
  min-height: 400px;
}

.documentsListFilter {
  position: relative;
}

.documentsListFilter > * {
  width: 100%;
}

.documentsListFilter h2 {
  margin-top: 0;
  margin-bottom: 10px;
}

.documentsListFilter .btnLetter {
  font-size: 12px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  order: 3;
  height: 30px;
  width: 130px;
  padding: 0 12px;
}

.documentsListFilter .categoriesNav {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 6px;
  grid-row-gap: 20px;
  order: 1;
  width: auto;
}

.documentsListFilter .categoriesNav li {
  width: 110px;
}

.documentsListFilter .categoriesNav li a:hover,
.documentsListFilter .categoriesNav li.active a {
  color: #ffffff;
  position: relative;
}

.documentsListFilter.filterNQA1 .categoriesNav li a:hover,
.documentsListFilter.filterNQA1 .categoriesNav li.active a {
  background: #61a447;
}

.documentsListFilter.filterNQA2 .categoriesNav li a:hover,
.documentsListFilter.filterNQA2 .categoriesNav li.active a {
  background: #fbd539;
}

.documentsListFilter.filterNQA3 .categoriesNav li a:hover,
.documentsListFilter.filterNQA3 .categoriesNav li.active a {
  background: #e395a5;
}

.documentsListFilter.filterNQA4 .categoriesNav li a:hover,
.documentsListFilter.filterNQA4 .categoriesNav li.active a {
  background: #accf6b;
}

.documentsListFilter.filterNQA5 .categoriesNav li a:hover,
.documentsListFilter.filterNQA5 .categoriesNav li.active a {
  background: #f3a23e;
}

.documentsListFilter.filterNQA6 .categoriesNav li a:hover,
.documentsListFilter.filterNQA6 .categoriesNav li.active a {
  background: #db308f;
}

.documentsListFilter.filterNQA7 .categoriesNav li a:hover,
.documentsListFilter.filterNQA7 .categoriesNav li.active a {
  background: #3a96cf;
}

.documentsListFilter .categoriesNav li.active a::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  position: absolute;
  bottom: -10px;
  right: 0;
  left: 0;
  margin: auto;
}

.documentsListFilter.filterNQA1 .categoriesNav li.active a::after {
  border-top: 10px solid #61a447;
}

.documentsListFilter.filterNQA2 .categoriesNav li.active a::after {
  border-top: 10px solid #fbd539;
}

.documentsListFilter.filterNQA3 .categoriesNav li.active a::after {
  border-top: 10px solid #e395a5;
}

.documentsListFilter.filterNQA4 .categoriesNav li.active a::after {
  border-top: 10px solid #accf6b;
}

.documentsListFilter.filterNQA5 .categoriesNav li.active a::after {
  border-top: 10px solid #f3a23e;
}

.documentsListFilter.filterNQA6 .categoriesNav li.active a::after {
  border-top: 10px solid #db308f;
}

.documentsListFilter.filterNQA7 .categoriesNav li.active a::after {
  border-top: 10px solid #3a96cf;
}

.documentsListFilter .categoriesNav li a {
  font-size: 12px;
  line-height: 28px;
  color: #b0b0b0;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  text-align: center;
}

.documentsListFilter .letterNav {
  display: none !important;
}

.documentsListFilter .letterNav li {
  width: 3.704%;
}

.documentsListFilter .letterNav li:not(:last-child) a {
  border-right: 1px solid #ebebeb;
}

.documentsListFilter .letterNav li a:hover,
.documentsListFilter .letterNav li.active a {
  color: #ffffff;
  background-color: #61a447;
}

.documentsListFilter .letterNav li a {
  font-size: 16px;
  transition: all 250ms ease-in-out;
  padding: 5px 0;
  color: #b0b0b0;
  background: #f9f9f9;
  box-sizing: border-box;
}

.documentsListFilter .letterNavMobile {
  position: absolute;
  bottom: -107px;
  width: 100%;
  max-width: 940px;
  background-color: #ffffff;
  box-shadow: 0 0px 10px 0 rgb(0 0 0 / 20%), 0 0px 15px 0 rgb(0 0 0 / 19%);
  display: none;
}

.documentsListFilter .letterNavMobile .letterNavright {
  width: 840px;
  border-left: 1px solid #ebebeb;
}

.letterNavMobile .letterNavright .letterNav {
  display: flex;
  width: 100%;
  height: 50px;
}

.letterNavMobile .letterNavright .letterNav li {
  width: 7.69%;
}

.letterNavMobile .letterNavright .letterNav a {
  background-color: unset !important;
  border-right: unset !important;
}

.letterNavMobile .letterNavright .letterNav a.active {
  color: #ffffff;
  background-color: #61a447 !important;
  border-radius: 50px;
  height: 30px;
  width: 30px;
}

.documentsListFilter .letterNavright > ul:last-child {
  border-top: 1px solid #ebebeb;
}

.documentsListFilter .letterNavMobile .letterNavLeft {
  width: 100px;
  padding: 20px;
  box-sizing: border-box;
}

.documentsListFilter .letterNavMobile .letterNavLeft a.active {
  height: 60px;
  width: 60px;
  background-color: #61a447;
  border-radius: 50%;
  color: #ffffff;
}

.documentsListContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 9px 0 0 0;
}

.documentFiles {
  margin-top: 25px;
  border-bottom: 1px solid #e7e7e7;
}

.documentFiles .file {
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
}

.documentFiles .file .fileContainer {
  padding-right: 30px;
}

.documentFiles .file .filelogo {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: #ffffff;
}

.documentFiles .file .filelogo.wordFile {
  background-color: #61a447;
}

.documentFiles .file .filelogo.pdfFile {
  background-color: #fbd539;
}

.documentFiles .file .filelogo.imageFile {
  background-color: #db308f;
}

.documentFiles .file .filelogo.videoFile {
  background-color: #accf6b;
}

.documentFiles .file .filelogo.excelFile {
  background-color: #3a96cf;
}

.documentFiles .file .filelogo.powerPointfile {
  background-color: #e395a5;
}

.documentFiles .file .filelogo.archiveFile {
  background-color: #f3a23e;
}

.documentFiles .file .filelogo.audioFile {
  background-color: #accf6b;
}

.documentFiles .file .filelogo.randomFile {
  background-color: #db308f;
}

.documentFiles .file .filelogo.urlFile {
  background-color: #388cef;
}

.documentFiles .file .fileContent {
  margin-left: 15px;
}

.documentFiles .file .fileContent .filename {
  font-size: 16px;
  line-height: 28px;
  color: #000000;
}

.documentFiles .file .fileContent .fileinfo span {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.documentFiles .file .btnContainer a {
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
}

.documentFiles .file .btnContainer a:hover {
  opacity: 0.8;
}

.documentFiles .file .btnContainer a svg {
  margin-right: 8px;
}

.documentFiles .file .btnContainer .blue {
  color: #388cef;
  background-color: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  padding: 15px 25px;
  width: unset;
}

.documentFiles .file .btnContainer .red {
  color: #f56666;
  background-color: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  padding: 15px 25px;
  width: unset;
}

.pagination {
  margin-top: 25px;
}

.pagination a:not(:last-child) {
  margin-right: 10px;
}

.pagination a {
  font-size: 16px;
  width: 68px;
  height: 60px;
  color: #222222;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 5px;
}

.pagination a:hover,
.pagination a.active {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.info {
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px 20px;
  max-width: 750px;
  margin-bottom: 25px;
  margin-left: 30px;
}

.info > span {
  color: #bbbbbb;
  margin-right: 20px;
}

.info p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
}

/* Modal */

/* #servicesListModal .modal-container { */
/* padding: 30px 35px 35px 35px; */
/* max-width: 500px; */
/* } */

#bookMentorSessionModal .modalContainer {
  max-width: 700px;
  padding: 70px 50px;
}

#bookMentorSessionModal .iconContainer {
  text-align: center;
}

#bookMentorSessionModal .iconContainer span {
  color: #ffffff;
  background: #8ac04a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

#bookMentorSessionModal .modalContent h2 {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #222222;
  margin-top: 15px;
}

#bookMentorSessionModal .modalContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #7d7d7d;
  margin: 8px 0 20px 0;
}

#bookMentorSessionModal .modalContent .form .twoCol {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  margin: 20px 0 50px 0;
}

#bookMentorSessionModal .modalContent .form .inputGroup input,
#bookMentorSessionModal .modalContent .form .inputGroup textarea {
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  line-height: 19px;
  color: #222222;
  background: #ffffff;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  width: 100%;
  outline: none;
}

#bookMentorSessionModal .modalContent .form .inputBtn {
  text-align: center;
}

#bookMentorSessionModal .modalContent .form .inputBtn button {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 20px;
}

#bookMentorSessionModal .modalContent .form .inputBtn button:hover {
  opacity: 0.8;
  cursor: pointer;
}

#bookMentorSessionModal .modalContent .form .inputGroup input::placeholder,
#bookMentorSessionModal .modalContent .form .inputGroup textarea::placeholder {
  color: #b7b8b7;
}

#bookMentorSessionModal .modalContent .form .inputGroup label {
  font-size: 16px;
  line-height: 19px;
  color: #272828;
  display: block;
  margin-bottom: 5px;
}

#videoFileModal .modalContainer .videoContent {
  margin-top: 40px;
}

#videoFileModal .modalContainer .videoContent > div {
  width: 50%;
}

#videoFileModal .modalContainer .video {
  position: relative;
}

#videoFileModal .modalContainer .video::after,
#videoFileModal .modalContainer .video::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#videoFileModal .modalContainer .video::after {
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 40px solid #ffffff;
  border-bottom: 20px solid transparent;
}

#videoFileModal .modalContainer .video::before {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  background-color: #222222;
}

#videoFileModal .modalContainer .video img {
  width: 100%;
}

#videoFileModal .modalContainer .videoContent .videoTitle {
  padding-right: 30px;
  box-sizing: border-box;
}

#videoFileModal .modalContainer .videoContent .videoTitle h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 38px;
  color: #ffffff;
  text-align: unset;
}

#videoFileModal .modalContainer .videoContent .downloadBtn {
  margin-top: 25px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn a {
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #ffffff;
  background: #404040;
  border: 1px solid #404040;
  box-sizing: border-box;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  padding: 15px 12px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn a > * {
  margin-right: 5px;
}

#videoFileModal .modalContainer .videoContent .downloadBtn span {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  display: block;
  margin-top: 5px;
}

#videoFileModal .modalContainer .videoContent .videoDesc p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 0;
}

#videoFileModal .modalContainer .videoContent .videoDesc > *:not(:last-child) {
  margin-bottom: 30px;
}

#videoFileModal .modalContainer .close {
  background-color: #3d3d3d;
}

#videoFileModal .modalContainer i {
  color: #ffffff;
}

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 100px;
  padding-bottom: 100px;
  width: 100%;
  height: unset;
  overflow: auto;
  background-color: rgb(34 34 34 / 40%);
  display: none;
  z-index: 9999999;
}

#videoFileModal .modalContainer {
  background: #222222;
  max-width: 1000px;
}

.modalContainer {
  background-color: #ffffff;
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: relative;
  padding: 50px;
  box-sizing: border-box;
}

#videoFileModal .closeModal {
  color: #ffffff;
  background-color: rgb(255 255 255 / 12%);
}

.closeModal {
  font-weight: 900;
  font-size: 16px;
  line-height: 18px;
  color: #f56666;
  background-color: rgb(245 102 102 / 12%);
  border: unset;
  outline: none;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.closeModal:hover {
  color: #ffffff;
  background-color: #f56666;
}

#servicesListModal h2 {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: #000000;
  text-align: center;
  margin-bottom: 16px;
}

#servicesListModal .servicesList {
  max-height: 500px;
  overflow-y: auto;
  padding-right: 10px;
}

#servicesListModal .servicesList li:not(:last-child) {
  margin-bottom: 10px;
}

#servicesListModal .servicesList li a {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  display: block;
  padding: 10px;
  text-align: center;
}

#servicesListModal .servicesList li a:hover,
#servicesListModal .servicesList li.active a {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #8ac04a;
}

.yearContainer {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.yearContainer ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 16px;
}

.yearContainer ul li:hover,
.yearContainer ul li.active {
  color: #ffffff;
  background: #8ac04a;
  border: 1px solid #8ac04a;
}

.yearContainer ul li {
  font-size: 16px;
  line-height: 32px;
  color: #b0b0b0;
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
}

.monthContainer ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

.monthContainer ul li {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 360px;
}

.monthContainer ul li.activeFocus:hover {
  background: #8ac04a;
  cursor: pointer;
}

.monthContainer ul li.activeFocus:hover p,
.monthContainer ul li.activeFocus:hover .month {
  color: #ffffff !important;
}

.monthContainer ul li.activeFocus:hover .focus {
  color: #8ac04a;
  background: #ffffff;
}

.monthContainer ul li.emptyFocus {
  align-items: center;
}

.monthContainer ul li.emptyFocus .activeFocusContent {
  padding: 0 20px 0 20px;
}

.monthContainer ul li .activeFocusContent {
  padding: 70px 10px 0 10px;
}

.monthContainer ul li.activeFocus {
  position: relative;
}

.monthContainer ul li.activeFocus .activeFocusBtnContent {
  width: 100%;
  position: absolute;
  bottom: 4px;
}

.monthContainer ul li.activeFocus .activeFocusBtnContent .activeFocusBtn {
  margin-right: 4px;
  margin-left: 4px;
}

.monthContainer ul li.emptyFocus .month {
  color: #808080;
}

.monthContainer ul li .month {
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
}

.monthContainer ul li.activeFocus .focus {
  background: #8ac04a;
}

.monthContainer ul li .focus {
  font-size: 12px;
  line-height: 28px;
  color: #f9f9f9;
  background: #7d7d7d;
  border-radius: 2px;
  padding: 4px 15px;
  margin-bottom: 30px;
  display: inline-block;
}

.monthContainer ul li p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

/* .monthContainer ul .activeFocus { */

/* } */

.monthContainer ul li.activeFocus:hover .activeFocusBtn {
  color: #8ac04a;
  background: #ffffff;
}

.monthContainer ul li.activeFocus .activeFocusBtn {
  font-size: 12px;
  line-height: 28px;
  color: #f9f9f9;
  background: #8ac04a;
  border-radius: 3px 3px 3px 2px;
  padding: 8px;
  display: block;
  /* width: 100%; */
  /* position: absolute; */
  /* left: 0; */
}

.dashboardBanner {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 90px 40px;
  box-sizing: border-box;
}

.dashboardContent {
  text-align: center;
  max-width: 600px;
  margin: auto;
}

.dashboardContent .contentHeader {
  color: #ffffff;
  margin-bottom: 10px;
}

.dashboardContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  margin: 0;
}

.innerPagesContainer {
  padding: 40px 30px 70px 30px;
  box-sizing: border-box;
  background-color: #ffffff;
}

.serviceLogo {
  display: flex;
  justify-content: center;
  margin-bottom: 35px;
}

.serviceLogo span {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 15px 50px;
  display: inline-block;
}

.serviceLogo span img {
  width: 100%;
  max-width: 150px;
}

.innerPagesContainer .searchContainer {
  max-width: 335px;
  margin: auto;
  display: none;
}

.innerPagesContainer .searchContainer .search {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 4px 2px 2px;
}

.innerPagesContainer .searchContainer input::placeholder {
  color: #b0b0b0;
}

.innerPagesContainer .searchContainer input {
  font-size: 16px;
  line-height: 10px;
  color: #222222;
  padding: 10px 15px;
  border: unset;
  outline: none;
  width: 260px;
}

.innerPagesContainer .searchContainer button {
  height: 33px;
  width: 33px;
  color: #ffffff;
  background: #8ac04a;
  border: unset;
  border-radius: 1px;
  cursor: pointer;
  outline: none;
}

.innerPagesContainer .searchContainer button:hover {
  opacity: 0.8;
}

.innerPagesContainer .searchContainer button > * {
  font-size: 17px;
}

.innerPagesContainer .helper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 16px;
  color: #7d7d7d;
  margin-bottom: 15px;
}

.innerPagesContainer .blueBtn {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  border-radius: 3px;
  cursor: pointer;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.innerPagesContainer .blueButton:hover {
  color: #ffffff;
  background-color: #388cef;
}

.innerPagesList {
  max-width: 670px;
  margin: 40px auto 0 auto;
}

.innerPagesList ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.innerPagesList li a {
  padding: 35px 15px;
  text-align: center;
  display: block;
}

.innerPagesList li:hover {
  background: #8ac04a;
  border: 1px solid #7bae3e;
}

.innerPagesList ul li {
  background: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
}

.innerPagesList .dashboardRight {
  margin-left: 30px;
}

.innerPagesList .dashboardIcon {
  display: flex;
  justify-content: center;
}

.innerPagesList li:hover .dashboardIcon span {
  color: #8ac04a;
  background: #ffffff;
}

.innerPagesList .dashboardIcon span {
  color: #ffffff;
  background: #8ac04a;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.innerPagesList .dashboardName {
  margin-top: 15px;
}

.innerPagesList li:hover .dashboardName {
  color: #ffffff;
}

.innerPagesList .dashboardName {
  font-size: 20px;
  line-height: 32px;
  color: #222222;
  display: block;
  margin-bottom: 5px;
}

.innerPagesList li:hover .dashboardDetails {
  color: #ebebeb;
}

.innerPagesList .dashboardDetails {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  display: block;
}

.serviceDetails {
  background-color: #ffffff;
  padding: 55px 30px 40px 30px;
}

.serviceDetailsContent {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 40px;
}

.serviceDetailsContent h2 {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: #222222;
}

.serviceDetailsContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  margin: 8px 0 0 0;
}

.serviceDetailsFilter {
  margin-top: 45px;
}

.serviceDetailsFilter .label {
  color: #000000;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 5px;
}

.serviceDetailsFilter > ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
}

.serviceDetailsFilter > ul li {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #b0b0b0;
  background-color: #f9f9f9;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 25px 20px;
}

.serviceDetailsFilter > ul li.active,
.serviceDetailsFilter > ul li:hover {
  color: #ffffff;
  background-color: #8ac04a;
  border: 1px solid #7bae3e;
}

.serviceDetailsFilter > ul li.active > svg,
.serviceDetailsFilter > ul li:hover > svg {
  fill: #ffffff;
}

.serviceDetailsFilter > ul li svg {
  fill: #b0b0b0;
  width: 45px;
}

.serviceDetailsFilter > ul li span {
  margin-left: 15px;
}

.serviceDetails .inputRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
}

.serviceDetails .inputContainer > .inputRow {
  margin-bottom: 20px;
}

.serviceDetails .inputGroup label {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
  display: block;
}

.serviceDetails .inputGroup input,
.serviceDetails .inputGroup textarea {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #222222;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px;
  outline: none;
  width: 100%;
}

.serviceDetails .inputGroup input::placeholder,
.serviceDetails .inputGroup textarea::placeholder {
  color: #b7b8b7;
}

.serviceDetails .inputGroup select {
  font-family: "Rubik", sans-serif;
  font-size: 20px;
  line-height: 24px;
  /* color: #222222; */
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 18px 20px;
  outline: none;
  width: 100%;

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.serviceDetails .inputGroup select::placeholder {
  color: #b7b8b7;
}

.serviceDetails .inputGroup select {
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='30' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98.5%;
  background-position-y: 50%;
}

.serviceDetails .inputContainer .inputActionButton {
  text-align: center;
  margin-top: 25px;
}

.serviceDetails .blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.serviceDetails .inputContainer {
  margin-top: 35px;
}

.serviceDetails .inputGroup textarea {
  height: 225px;
}

.branding {
  padding: 70px 0 0 0;
}

.branding .brandingContent {
  text-align: center;
}

.branding .brandingContent {
  max-width: 640px;
  margin: 0 auto 40px auto;
  text-align: center;
}

.branding .brandingContent h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;
  color: #272828;
}

.branding .brandingContent p {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
  margin: 15px 0 0 0;
}

.branding .uploadFile {
  background: #f9f9f9;
  border: 1px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 90px;
  text-align: center;
}

.branding .uploadFile .uploadFileText {
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  color: #7d7d7d;
  opacity: 0.4;
  display: block;
  margin-bottom: 18px;
}

.branding .btnSaveBranding {
  border-top: 1px solid #ebebeb;
  text-align: center;
  padding-top: 40px;
  margin-top: 30px;
}
.serviceDetails .blueButton {
  font-weight: 400;
  font-size: 16px;
  color: #388cef;
  background: rgba(56, 140, 239, 0.1);
  border: 1px solid rgba(56, 140, 239, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.serviceDetails .blueButton:hover {
  color: #ffffff;
  background: #388cef;
  border: 1px solid #388cef;
}

.branding .uploadFile .blueButton input {
  display: none;
}

.branding .photoUploaded {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 20px 40px 20px 20px;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.branding .photoUploaded .btnRemove button {
  font-weight: 400;
  font-size: 16px;
  color: #f56666;
  background: #fde0e0;
  border: 1px solid rgba(245, 102, 102, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  padding: 15px 20px;
  text-transform: uppercase;
  outline: none;
}

.branding .photoUploaded .btnRemove button:hover {
  color: #ffffff;
  background: #f56666;
  border: 1px solid #f56666;
}

.branding .photoUploaded .btnRemove button > * {
  margin-left: 5px;
}

.branding .photoUploadedContent .photoInfo {
  margin-left: 20px;
}
.branding .photoUploadedContent .photoInfo > span {
  display: block;
}

.branding .photoUploadedContent .photoInfo .photoName {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #272828;
}

.branding .photoUploadedContent .photoInfo .photoSize {
  font-weight: 300;
  font-size: 16px;
  line-height: 25px;
  color: #7d7d7d;
}

.branding .photoUploadedContent {
  display: flex;
  align-items: center;
}
.branding .photoUploadedContent img {
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  max-width: 150px;
}

@media screen and (max-width: 1250px) {
  .portalFooter .footerBottom .footerRow {
    flex-wrap: wrap;
  }

  .portalFooter .footerBottom .footerMenu {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px 0;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
  }

  .portalFooter .footerBottom .footerCopyright {
    width: 100%;
    text-align: center;
    padding: 30px 0;
  }
}

@media screen and (max-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: none !important;
  }
}

@media screen and (min-width: 1024px) {
  .bottomHeader .headerMenu .mainHeaderMenu {
    display: flex;
  }

  .mobileMenu {
    display: none;
  }

  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 12px;
  }

  #videoFileModal .modalContainer {
    max-width: 1400px;
  }

  .monthContainer ul li .activeFocusContent {
    padding: 70px 20px 0 20px;
  }
  .topHeader .topHeaderLeft .topHeaderMenu li:not(:last-child) {
    margin-right: 20px;
  }
  .topHeader .topHeaderLeft .topHeaderMenu li a {
    font-size: 16px;
    line-height: 32px;
  }
}

@media screen and (min-width: 1100px) {
  .documentsListFilter .letterNav {
    display: flex !important;
  }

  .documentsListFilter h2 {
    margin: 35px 0 20px 0;
  }

  .documentsListFilter .btnLetter {
    display: none !important;
  }

  .documentsListFilter .categoriesNav {
    order: unset;
    width: auto;
  }

  .bottomHeader .headerMenu .searchBtn {
    display: block;
  }

  .bottomHeader .headerRow {
    padding: 10px 20px 10px 10px;
  }

  .headerRow {
    padding: 10px 20px;
  }

  .topHeader .topHeaderRight .services .selectedService {
    width: unset;
    overflow: unset;
    white-space: unset;
    text-overflow: unset;
  }

  .documentsListFilter .categoriesNav {
    grid-template-columns: repeat(5, 1fr);
  }

  .documentsListFilter .categoriesNav li {
    width: 160px;
  }
  .documentsListFilter .categoriesNav li a {
    font-size: 16px;
    line-height: 32px;
  }
  .documentsListFilter .btnLetter {
    font-size: 16px;
    height: 40px;
    width: 165px;
  }
  .tabHeader .menu .tabMenuItem {
    font-size: 16px;
  }
}

/*Search Results*/

.documentsList.searchResults {
  padding: unset;
}

.documentsList.searchResults .searchResultList {
  padding: 60px 30px 50px 30px;
}

.documentsList.searchResults .typeOfDocuments:not(:first-child) {
  margin-top: 50px;
}

.documentsList.searchResults .documentFiles {
  border-bottom: unset;
}

.documentsList.searchResults .documentFiles .file:not(:last-child) {
  margin-bottom: 10px;
}

.documentsList.searchResults .documentFiles .file {
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
}

.documentsList.searchResults .searchResultFooter {
  padding: 50px 30px;
  border-top: 1px solid #e7e7e7;
}

.documentsList.searchResults .searchResultFooter .pagination {
  margin-top: 0;
}

.documentsList.searchResults .btnContainer {
  display: flex;
  align-items: center;
}

.documentsList.searchResults .nqaType {
  margin-right: 35px;
}

.documentsList.searchResults .nqaType span {
  font-size: 16px;
  line-height: 28px;
  color: #8ac04a;
  background: rgb(138 192 74 / 20%);
  border: 1px solid #8ac04a;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 2px 5px;
}

.documentsList .trialPrompt {
  border-top: 1px solid #e7e7e7;
  margin-top: 25px;
  padding-top: 25px;
}

.documentsList .trialPrompt .contentPrompt {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e7e7;
  background: #f9f9f9;
  font-weight: 900;
  min-height: 300px;
}

@media screen and (min-width: 1150px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 15px;
  }
}

@media screen and (min-width: 1225px) {
  .products {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1251px) {
  .portalFooter .footerBottom .footerRow {
    padding: 15px 25px;
    box-sizing: border-box;
  }
}

@media screen and (min-width: 1270px) {
  .bottomHeader .headerMenu .mainHeaderMenu .menuItem a {
    font-size: 17px;
  }
  .documentsListFilter .categoriesNav {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: 1440px) {
  #videoFileModal .modal-container {
    max-width: 1440px;
  }
}
